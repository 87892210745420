import React from 'react';
import { Link } from 'gatsby';

import { Image } from '@elements';
import BlockContent from '@components/BlockContent';

export const TeamMember = ({ data }) => (
  <div className="flex flex-col overflow-hidden">
    {/* <img src="/images/wood.png" className="z-10" alt="wood plank" /> */}
    <div className="relative pb-full -mt-10">
      <Image
        className="absolute h-full w-full"
        imageClasses="h-full w-full object-cover"
        image={data.image}
      />
    </div>

    <div className="flex-1 p-6 flex flex-col justify-between">
      <div className="text-center flex-1 pb-6 sm:text-left">
        {data?.pageInfo?.name && (
          <h2 className="text-2xl mb-0">{data.pageInfo.name}</h2>
        )}
        {data?.role && (
          <span className="text-lg mb-0 text-secondary">{data.role}</span>
        )}
        {data._rawBio && (
          <div className="mt-3 text-sm">
            <BlockContent blocks={data._rawBio || []} />
          </div>
        )}
      </div>

      <SocialMedia data={data.socialMediaHandles} className="justify-center" />
    </div>
  </div>
);

export const TeamMemberWithLink = ({ data }) => (
  <div className="flex flex-col overflow-hidden">
    <img src="/images/wood.png" className="z-10" alt="wood plank" />
    <div className="relative pb-full -mt-10">
      <Image
        className="absolute h-full w-full"
        imageClasses="h-full w-full object-cover"
        image={data.image}
      />
    </div>

    <div className="flex-1 p-6 flex flex-col justify-between">
      <div className="text-center flex-1 pb-4 sm:text-left">
        {data?.pageInfo?.name && (
          <h2 className="text-2xl mb-0">{data.pageInfo.name}</h2>
        )}
        {data?.role && (
          <span className="text-lg mb-0 text-secondary">{data.role}</span>
        )}
      </div>
      <SocialMedia
        data={data.socialMediaHandles}
        className="mb-2 justify-center"
      />
      {data?.pageInfo?.slug?.current && (
        <Link
          className="btn w-full mt-3"
          to={data.pageInfo.slug.current}
          title={data?.pageInfo?.name || ''}
        >
          See Full Bio
        </Link>
      )}
    </div>
  </div>
);

export const SocialMedia = ({ data, className }) => (
  <>
    {data &&
      (data.facebook ||
        data.instagram ||
        data.twitter ||
        data.linkedIn ||
        data.pinterest ||
        data.youtube) && (
        <div
          className={`space-x-4 flex border-t border-gray-300 flex-wrap pt-6 ${className}`}
        >
          {data?.facebook && (
            <a
              href={`${data.facebook}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-secondary"
            >
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          )}
          {data?.instagram && (
            <a
              href={`${data.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-secondary"
            >
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          )}
          {data?.twitter && (
            <a
              href={`${data.twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-secondary"
            >
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          )}
          {data?.linkedIn && (
            <a
              href={`${data.linkedIn}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-secondary"
            >
              <span className="sr-only">LinkedIn</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M6.46442 21.9543V8.60673H2.31236V21.9543h4.15206zM4.3663 6.82097c1.33932 0 2.41048-1.11535 2.41048-2.45467C6.77678 3.07116 5.70562 2 4.3663 2 3.07116 2 2 3.07116 2 4.3663c0 1.33932 1.07116 2.45467 2.3663 2.45467zM21.9985 21.9543v-7.3229c0-3.5715-.803-6.33855-4.9993-6.33855-2.0097 0-3.349 1.11535-3.928 2.14235h-.0458V8.60673H9.0547V21.9543h4.1521v-6.6067c0-1.7416.3123-3.3933 2.4531-3.3933 2.1438 0 2.188 1.9656 2.188 3.5273v6.4727H22h-.0015z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          )}
          {data?.pinterest && (
            <a
              href={`${data.pinterest}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-secondary"
            >
              <span className="sr-only">Pinterest</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M17.0194 3.34117C15.4874 2.447 13.8141 2 12 2c-1.8144 0-3.48762.447-5.01966 1.34117-1.53204.89421-2.74516 2.10734-3.63917 3.63917C2.447 8.51256 2 10.1858 2 12c0 2.0225.55098 3.8585 1.65356 5.5076 1.10238 1.6496 2.54757 2.869 4.33595 3.6588-.03488-.9288.02159-1.6969.16929-2.3045l1.28899-5.4427c-.21704-.4253-.32548-.9547-.32548-1.5885 0-.7378.18662-1.3543.55986-1.84888.37303-.49497.82893-.74247 1.36713-.74247.4339 0 .7683.14307 1.0027.42987.2344.28635.3517.64668.3517 1.08058 0 .2693-.0501.5971-.1497.9832-.1.3863-.2302.8333-.3906 1.3412-.1606.5077-.2755.9134-.3451 1.2174-.1217.5293-.0217.9829.2995 1.3606.321.3776.7465.5663 1.2761.5663.9288 0 1.6905-.5163 2.2852-1.5493.5946-1.0331.8919-2.2874.8919-3.7632 0-1.13705-.3666-2.06172-1.1002-2.77348-.7336-.7116-1.7559-1.06771-3.0664-1.06771-1.4672 0-2.6543.47111-3.56118 1.41263-.90709.94196-1.36065 2.06836-1.36065 3.37876 0 .7813.2215 1.4369.66404 1.9664.14753.1736.19529.3601.14328.5596-.01754.0522-.05218.1823-.10418.3905-.05198.2082-.08686.3431-.10419.4037-.06955.2779-.23438.3648-.49497.2604-.66846-.2778-1.17635-.7594-1.52337-1.4452-.34724-.6857-.52077-1.4799-.52077-2.3827 0-.5816.09321-1.1633.27983-1.74488.18662-.58161.47721-1.14357.87245-1.68633.39479-.54211.868-1.02206 1.41939-1.43863.55119-.41679 1.2218-.75069 2.01178-1.00285.78984-.25151 1.64064-.37745 2.55214-.37745 1.2326 0 2.3395.27347 3.3203.8202.9808.54697 1.7296 1.25456 2.2461 2.12253.5163.868.7748 1.79661.7748 2.78661 0 1.302-.2259 2.474-.6771 3.5157-.4514 1.0417-1.0895 1.8598-1.9142 2.4544-.8249.5945-1.7624.8918-2.8125.8918-.5296 0-1.0244-.1236-1.4845-.3711-.4603-.2475-.7771-.5404-.9506-.879-.3906 1.5362-.625 2.4525-.7032 2.7473-.165.6165-.5036 1.328-1.0157 2.1354.9289.2777 1.875.4165 2.8386.4165 1.8142 0 3.4874-.4469 5.0194-1.3409 1.532-.8942 2.7451-2.1073 3.6394-3.6396C21.5528 15.4865 22 13.8135 22 11.9991s-.4472-3.48734-1.3412-5.01959c-.894-1.531-2.1074-2.74413-3.6394-3.63834z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          )}
          {data?.youtube && (
            <a
              href={`${data.youtube}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-secondary"
            >
              <span className="sr-only">Youtube</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M11.9938 5s-6.25082.00001-7.81969.40767c-.84003.23471-1.53173.92642-1.76644 1.7788C2.00001 8.75534 2 12.0044 2 12.0044s.00001 3.2613.40767 4.8055c.23471.8523.91406 1.5317 1.76644 1.7664 1.58123.42 7.81969.42 7.81969.42s6.2632 0 7.8321-.4076c.8523-.2347 1.5317-.9017 1.754-1.7665C22 15.2657 22 12.0167 22 12.0167s.0123-3.26136-.4201-4.83023c-.2223-.85238-.9017-1.53172-1.754-1.75408C18.257 5.00002 11.9938 5 11.9938 5zm-1.9889 4.00254l5.2008 3.00186-5.2008 2.9894V9.00254z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          )}
        </div>
      )}
  </>
);
