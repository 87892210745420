import React from 'react';
import { graphql } from 'gatsby';

import ContentNarrow from '@components/ContentNarrow';
import Layout from '@components/Layout';
import { Container } from '@elements';

import { TeamMember, TeamMemberWithLink } from '../components/TeamMember';

const teamMember = ({ data }) => {
  const { _rawContent, team, createPageForEach } = data.sanityTeamSettings;
  return (
    <Layout>
      <div className="color-mode-background-200">
        <ContentNarrow rawData={_rawContent} className="pb-0" />
        <Container className="">
          <div
            className={`mt-12 grid gap-8 mx-auto md:grid-cols-${
              team.length > 2 ? 2 : team.length
            } lg:grid-cols-${team.length > 3 ? 3 : team.length} xl:grid-cols-${
              team.length > 4 ? 4 : team.length
            } lg:max-w-none`}
          >
            {createPageForEach ? (
              <>
                {team.map((member) => (
                  <TeamMemberWithLink
                    className="max-w-3xl"
                    key={member._id}
                    data={member}
                  />
                ))}
              </>
            ) : (
              <>
                {team.map((member) => (
                  <TeamMember
                    className="max-w-3xl"
                    key={member._id}
                    data={member}
                  />
                ))}
              </>
            )}
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default teamMember;

export const query = graphql`
  query {
    sanityTeamSettings {
      _rawContent(resolveReferences: { maxDepth: 10 })
      team {
        _rawBio(resolveReferences: { maxDepth: 10 })
        pageInfo {
          slug {
            current
          }
          name
        }
        _id
        _key
        image {
          asset {
            _id
          }
        }
        isActive
        role
        socialMediaHandles {
          ...SocialMediaHandlesFragment
        }
      }
      createPageForEach
    }
  }
`;
